const dateFormatterDB = new Intl.DateTimeFormat("fr-CA")

const TODAY = "TODAY"
const WEEK = "WEEK"
const MONTH = "MONTH"
const LAST_7_DAYS = "LAST_7_DAYS"
const LAST_30_DAYS = "LAST_30_DAYS"

export const PeriodType = { TODAY, WEEK, MONTH, LAST_7_DAYS, LAST_30_DAYS }

export const PeriodOptions = [
  { key: "week", value: PeriodType.WEEK, text: "Semana Atual" },
  { key: "month", value: PeriodType.MONTH, text: "Mês" },
  { key: "days", value: PeriodType.LAST_7_DAYS, text: "Utimos 7 dias" },
  { key: "daysmonth", value: PeriodType.LAST_30_DAYS, text: "Ultimos 30 dias" },
]

export function getOption(periodType) {
  let filteredOption = PeriodOptions.filter((option) => {
    return option.value == periodType
  })

  if (filteredOption.length > 0) {
    return filteredOption[0].text
  }

  return null
}

export const dateToString = (stringDate) => {
  let result = null
  if (stringDate) {
    if (stringDate instanceof Date) {
      return stringDate.toLocaleDateString()
    } else {
      let dateObj = new Date(stringDate + " 00:00:00")
      return dateObj.toLocaleDateString()
    }
  }
  return null
}

export const dateAdd = (referenceDate, days) => {
  let dateAsLong = referenceDate
    ? referenceDate.getTime()
    : new Date().getTime()
  let tempDate = new Date(dateAsLong)
  if (days) {
    tempDate.setDate(tempDate.getDate() + days)
  }
  return tempDate
}

export const dateSub = (referenceDate, days) => {
  let dateAsLong = referenceDate
    ? referenceDate.getTime()
    : new Date().getTime()
  let tempDate = new Date(dateAsLong)
  if (days) {
    tempDate.setDate(tempDate.getDate() - days)
  }
  return tempDate
}

export const formatDateToDB = (referenceDate) => {
  return dateFormatterDB.format(referenceDate)
}

export const getPeriod = (referenceDate, periodType) => {
  let dateAsLong = referenceDate
    ? referenceDate.getTime()
    : new Date().getTime()

  let initialDate = new Date(dateAsLong)
  let finalDate = new Date(dateAsLong)

  let dayIndex = initialDate.getDay()
  if (dayIndex == 0) {
    dayIndex = 7
  } else {
    dayIndex = dayIndex - 1
  }

  switch (periodType) {
    case PeriodType.WEEK:
      initialDate = dateSub(initialDate, dayIndex)
      break
    case PeriodType.MONTH:
      initialDate.setDate(1)
      break
    case PeriodType.LAST_7_DAYS:
      initialDate.setDate(initialDate.getDate() - 6)
      break
    case PeriodType.LAST_30_DAYS:
      initialDate.setDate(initialDate.getDate() - 29)
      break
    default:
      break
  }

  let period = {
    initialDate: formatDateToDB(initialDate),
    finalDate: formatDateToDB(finalDate),
  }

  return period
}

export const getPeriods = (referenceDate, periodType) => {
  let currentDateAsLong = referenceDate
    ? referenceDate.getTime()
    : new Date().getTime()
  let currentDate = new Date(currentDateAsLong)
  let previousDate = new Date(currentDateAsLong)
  let nextDate = new Date(currentDateAsLong)

  if (periodType == PeriodType.WEEK || periodType == PeriodType.LAST_7_DAYS) {
    previousDate = dateSub(previousDate, 7)
    nextDate = dateAdd(nextDate, 7)
  } else {
    previousDate.setMonth(previousDate.getMonth() - 1)
    nextDate.setMonth(nextDate.getMonth() + 1)
  }

  return {
    current: getPeriod(currentDate, periodType),
    previous: getPeriod(previousDate, periodType),
    next: getPeriod(nextDate, periodType),
  }
}
