import React from "react"
import format from "../common/helper/format"
import Loader from "../common/misc/Loader"
import BoxInfo from "./BoxInfo"
import ComparablePanel from "./ComparablePanel"

const ComparableBoxInfo = ({
  title,
  icon,
  mask,
  periods,
  periodType,
  queryProps,
  getValue,
}) => {
  let currentValue = 0
  let comparableValue = 0

  const queryData = queryProps?.data
  if (queryData && getValue) {
    const mainData = queryData["principal"]
    const comparableData = queryData["comparacao"]

    currentValue = getValue(mainData.aggregate)
    comparableValue = getValue(comparableData.aggregate)
  }

  return (
    <Loader {...queryProps}>
      <BoxInfo
        icon={icon}
        title={title}
        value={currentValue}
        mask={mask}
        info={
          <ComparablePanel
            currentValue={currentValue}
            comparableValue={comparableValue}
            periodType={periodType}
            periods={periods}
          />
        }
      />
    </Loader>
  )
}

export default ComparableBoxInfo
