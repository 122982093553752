import numeral from "numeral"
import moment from "moment"

// From https://github.com/arthurdenner/format-string-by-pattern/blob/master/src/index.js
function formatStringByPattern(pattern, value) {
  if (!value) {
    return value
  }

  if (!pattern) {
    return value
  }

  const cleanValue = String(value).replace(/[^\da-zA-Z\n|]/g, "")
  const blockSizes = pattern
    .split(/[^\da-zA-Z\n|]/g)
    .filter(Boolean)
    .map((b) => b.length)
  const separators = pattern.split(/[\da-zA-Z\n|]/g).filter(Boolean)
  const [firstSeparator] = separators

  if (pattern.startsWith(firstSeparator)) {
    let curBlockSize
    let beforeSlice
    let afterSlice
    let nextResult

    const afterReduce = separators.reduce(
      (acc, cur, index) => {
        curBlockSize = blockSizes[index]
        beforeSlice = acc.value.slice(0, curBlockSize)
        afterSlice = acc.value.slice(curBlockSize)
        nextResult = beforeSlice
          ? acc.result.concat(cur, beforeSlice)
          : acc.result

        return {
          result: nextResult,
          value: afterSlice,
        }
      },
      {
        result: "",
        value: cleanValue,
      }
    )

    return afterReduce.result.slice(0, pattern.length)
  }

  let curSeparator
  let replace
  let curSlice
  let curRegex
  let curValue

  const afterReduce = blockSizes.reduce(
    (acc, cur, index) => {
      curSeparator = separators[index] || ""
      replace = `$1${curSeparator}$2`
      curSlice = cur + acc.prevSlice + acc.prevSeparator.length
      curRegex = new RegExp(`(.{${curSlice}})(.)`)
      curValue = acc.value.replace(curRegex, replace)

      return {
        prevSeparator: curSeparator,
        prevSlice: curSlice,
        value: curValue,
      }
    },
    {
      prevSeparator: "",
      prevSlice: 0,
      value: cleanValue,
    }
  )

  return afterReduce.value.slice(0, pattern.length)
}

const MaskType = {
  NATURAL_DOCUMENT: "000.000.000-00",
  LEGAL_DOCUMENT: "00.000.000/0000-00",
  POSTAL_CODE: "00000-000",
  CONTACT_MOBILE: "(00) 00000-0000",
  CONTACT_LANDLINE: "(00) 0000-0000",
}

const maskDocument = (value) => {
  if (value) {
    if (value.length === 11) {
      return formatStringByPattern(MaskType.NATURAL_DOCUMENT, value)
    } else if (value.length === 14) {
      return formatStringByPattern(MaskType.LEGAL_DOCUMENT, value)
    } else {
      return value
    }
  }
  return ""
}

const maskContact = (info, type) => {
  if (info) {
    if (type === "LANDLINE") {
      return formatStringByPattern(MaskType.CONTACT_MOBILE, info)
    } else if (type === "MOBILE") {
      return formatStringByPattern(MaskType.CONTACT_MOBILE, info)
    }
    return info
  }
  return ""
}

const FormatUtil = {
  decimal: (value) => numeral(value).format("0,0.00"),
  money: (value) => numeral(value).format("$0,0.00"),
  date: (value) => moment.utc(value).format("L"),
  mask: (pattern, value) => formatStringByPattern(pattern, value),
  maskDocument: maskDocument,
  maskContact: maskContact,
  MASK_NATURAL_DOCUMENT: MaskType.NATURAL_DOCUMENT,
  MASK_LEGAL_DOCUMENT: MaskType.LEGAL_DOCUMENT,
  MASK_POSTAL_CODE: MaskType.POSTAL_CODE,
  MASK_CONTACT_MOBILE: MaskType.CONTACT_MOBILE,
  MASK_CONTACT_LANDLINE: MaskType.CONTACT_LANDLINE,
}

export default FormatUtil
