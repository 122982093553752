import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { DASHBOARD_QTDE_PESSOA_QUERY } from "./gql/queries"
import ComparableBoxInfo from "../ComparableBoxInfo"
import { getPeriods } from "../../common/helper/dateUtils"

const getFiltros = (period) => {
  return {
    _and: [
      { datainclusao: { _gte: period.initialDate } },
      { datainclusao: { _lte: period.finalDate } },
    ],
  }
}

const ClienteNovoQuantidadePanel = ({ periodo }) => {
  let periodos = getPeriods(new Date(), periodo)
  const variables = {
    filtro_principal: getFiltros(periodos.current),
    filtro_comparacao: getFiltros(periodos.previous),
  }

  const queryProps = useQuery(DASHBOARD_QTDE_PESSOA_QUERY, {
    variables,
  })

  return (
    <ComparableBoxInfo
      periods={periodos}
      icon="user"
      title={"Clientes novos"}
      periodType={periodo}
      queryProps={queryProps}
      getValue={(aggregate) => {
        return aggregate ? aggregate.count : 0
      }}
    />
  )
}

export default ClienteNovoQuantidadePanel
