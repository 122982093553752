import React from "react"
import { Icon } from "semantic-ui-react"

const BoxInfo = ({ icon, title, value, mask, info }) => {
  return (
    <div className="flex flex-col border-2 bg-white rounded-lg p-2 m-2 relative shadow-md mt-6">
      {icon && (
        <div className="flex flex-row items-center justify-center bg-blue-600 w-16 h-16 rounded-lg absolute left-4 -top-4 shadow-lg">
          <Icon name={icon} inverted size="big" />
        </div>
      )}
      <div className="flex flex-col divide-y-2 gap-2">
        <div className="flex flex-col items-end">
          <span className="text-lg text-gray-600">{title}</span>
          <span className="text-3xl text-gray-700 font-extrabold">
            {mask ? mask(value) : value}
          </span>
        </div>
        <span className="p-2">{info}</span>
      </div>
    </div>
  )
}

export default BoxInfo
