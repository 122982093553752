import gql from "graphql-tag"

export const LIST_STORE_QUERY = gql`
  query list_store {
    stores: loja(order_by: { apelido: asc }) {
      id
      apelido
    }
  }
`

export const SALES_SUMMARY_FRAGMENT = gql`
  fragment sales_summary_fragment on resumo_venda_aggregate {
    aggregate {
      sum {
        valortotalbruto
        valortotalliquido
        custototal
        quantidade
      }
      count(columns: [movimento_id], distinct: true)
    }
  }
`

export const SALES_SUMMARY_QUERY = gql`
  query sales_summary_list(
    $store: loja_bool_exp
    $saller: funcionario_bool_exp
    $payment_term: condicao_pagamento_bool_exp
    $department: departamento_bool_exp
    $year: ano_bool_exp
    $month: mes_bool_exp
    $day: dia_bool_exp
    $hour: hora_bool_exp
    $filters: resumo_venda_bool_exp!
  ) {
    list_by_store: loja(where: $store) {
      id
      apelido
      resumovendas_aggregate(where: $filters) {
        ...sales_summary_fragment
      }
    }
    list_by_saller: funcionario(
      where: $saller
      order_by: { pessoafisica: { pessoa: { nome: asc } } }
    ) {
      id
      pessoafisica {
        pessoa {
          nome
        }
      }
      resumovendas_aggregate(where: $filters) {
        ...sales_summary_fragment
      }
    }
    list_by_payment_term: condicao_pagamento(
      where: $payment_term
      order_by: { descricao: asc }
    ) {
      id
      descricao
      resumovendas_aggregate(where: $filters) {
        ...sales_summary_fragment
      }
    }
    list_by_department: departamento(
      where: $department
      order_by: { descricao: asc }
    ) {
      id
      descricao
      resumovendas_aggregate(where: $filters) {
        ...sales_summary_fragment
      }
    }
    list_by_year: ano(where: $year, order_by: { codigo: asc }) {
      codigo
      resumovendas_aggregate(where: $filters) {
        ...sales_summary_fragment
      }
    }
    list_by_month: mes(where: $month, order_by: { codigo: asc }) {
      sigla
      resumovendas_aggregate(where: $filters) {
        ...sales_summary_fragment
      }
    }
    list_by_day: dia(where: $day, order_by: { codigo: asc }) {
      codigo
      resumovendas_aggregate(where: $filters) {
        ...sales_summary_fragment
      }
    }
    list_by_hour: hora(where: $hour, order_by: { codigo: asc }) {
      codigo
      resumovendas_aggregate(where: $filters) {
        ...sales_summary_fragment
      }
    }
  }
  ${SALES_SUMMARY_FRAGMENT}
`
