import React, { useState } from "react"
import { Icon } from "semantic-ui-react"
import { dateToString, getOption } from "../common/helper/dateUtils"
import format from "../common/helper/format"

const ComparablePanel = ({
  currentValue,
  comparableValue,
  periodType,
  periods,
}) => {
  const [displayPeriods, setDisplayPeriods] = useState()
  let isNegative = false

  let percentage = 100.0

  if (comparableValue && comparableValue > 0.0) {
    if (currentValue && currentValue > 0.0) {
      isNegative = currentValue < comparableValue
      percentage = (currentValue / comparableValue - 1) * 100.0
    } else {
      percentage = 0.0
    }
  }

  return (
    <div>
      <div className="flex flex-row justify-between p-2">
        <span>{`${format.decimal(percentage)} % `}</span>
        <span>
          {isNegative ? (
            <Icon name="angle double down" color="red" />
          ) : (
            <Icon name="angle double up" color="green" />
          )}
        </span>
        <span>{getOption(periodType)}</span>
        <span>
          <Icon
            name={displayPeriods ? "chevron up" : "chevron down"}
            className="cursor-pointer"
            onClick={() => {
              setDisplayPeriods(!displayPeriods)
            }}
          />
        </span>
      </div>
      {displayPeriods && (
        <div className="flex flex-col items-center border-2 bg-slate-200 rounded-md px-2">
          <div className="flex flex-row justify-between  w-full">
            <span>{dateToString(periods.current.initialDate)}</span>
            <span>{dateToString(periods.current.finalDate)}</span>
          </div>
          <div className="flex flex-row justify-between  w-full">
            <span>{dateToString(periods.previous.initialDate)}</span>
            <span>{dateToString(periods.previous.finalDate)}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default ComparablePanel
