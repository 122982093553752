import React from "react"
import { Icon } from "semantic-ui-react"

const BoxChart = ({ title, navbar, children }) => {
  return (
    <div className="flex flex-col border-2 bg-white rounded-lg p-2  m-2 shadow-lg">
      <div className="flex flex-row w-full text-xl font-extrabold bg-blue-600 rounded-md p-2 text-white shadow-lg">
        <Icon name="chart pie" /> {title}
      </div>
      <div className="w-full overflow-auto">
        <div className="h-80 min-w-[920px] p-2">{children}</div>
      </div>
      <div className="flex justify-center p-2">{navbar}</div>
    </div>
  )
}

export default BoxChart
