import * as React from "react"
import { Loader as SemanticLoader, Dimmer } from "semantic-ui-react"

const Loader = ({ error, loading, children }) => {
  if (error) {
    return <div>Error: {error.message}</div>
  }

  if (children) {
    return loading ? (
      <SemanticLoader active inline="centered" />
    ) : (
      <div>{children}</div>
    )
  }

  // We could use active={loading} but then the Dimmer ends up using space
  return (
    loading && (
      <Dimmer active>
        <SemanticLoader />
      </Dimmer>
    )
  )
}

export default Loader
