import gql from "graphql-tag"

export const DASHBOARD_QTDE_PEDIDO_QUERY = gql`
  fragment dashboard_qtde_pedido_fragment on ficha_aggregate {
    aggregate {
      count
    }
  }

  query listar_dashboard_qtde_pedido(
    $filtro_principal: ficha_bool_exp!
    $filtro_comparacao: ficha_bool_exp!
  ) {
    principal: ficha_aggregate(where: $filtro_principal) {
      ...dashboard_qtde_pedido_fragment
    }

    comparacao: ficha_aggregate(where: $filtro_comparacao) {
      ...dashboard_qtde_pedido_fragment
    }
  }
`

export const DASHBOARD_QTDE_PESSOA_QUERY = gql`
  fragment dashboard_qtde_pessoa_fragment on pessoa_aggregate {
    aggregate {
      count
    }
  }

  query listar_dashboard_qtde_pessoa(
    $filtro_principal: pessoa_bool_exp!
    $filtro_comparacao: pessoa_bool_exp!
  ) {
    principal: pessoa_aggregate(where: $filtro_principal) {
      ...dashboard_qtde_pessoa_fragment
    }

    comparacao: pessoa_aggregate(where: $filtro_comparacao) {
      ...dashboard_qtde_pessoa_fragment
    }
  }
`

export const DASHBOARD_VALOR_PEDIDO_QUERY = gql`
  fragment dashboard_valor_pedido_fragment on ficha_item_aggregate {
    aggregate {
      sum {
        desconto
        valortotalbruto
        valortotalliquido
      }
    }
  }

  query listar_dashboard_qtde_pedido(
    $filtro_principal: ficha_item_bool_exp!
    $filtro_comparacao: ficha_item_bool_exp!
  ) {
    principal: ficha_item_aggregate(where: $filtro_principal) {
      ...dashboard_valor_pedido_fragment
    }

    comparacao: ficha_item_aggregate(where: $filtro_comparacao) {
      ...dashboard_valor_pedido_fragment
    }
  }
`
