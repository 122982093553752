import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { DASHBOARD_QTDE_PEDIDO_QUERY } from "./gql/queries"
import ComparableBoxInfo from "../ComparableBoxInfo"
import { getPeriods } from "../../common/helper/dateUtils"

const getFiltros = (period, hasPermissao, userId) => {
  let filters = {
    _and: [
      { data: { _gte: period.initialDate } },
      { data: { _lte: period.finalDate } },
    ],
  }

  if (!hasPermissao) {
    filters.itens = {
      vendedor_id: { _eq: userId },
    }
  }

  return filters
}

const QuantidadePedidoPanel = ({ periodo, hasPermissao, userId }) => {
  const periodos = getPeriods(new Date(), periodo)

  const variables = {
    filtro_principal: getFiltros(periodos.current, hasPermissao, userId),
    filtro_comparacao: getFiltros(periodos.previous, hasPermissao, userId),
  }

  const queryProps = useQuery(DASHBOARD_QTDE_PEDIDO_QUERY, {
    variables,
  })

  return (
    <ComparableBoxInfo
      periods={periodos}
      icon="shopping bag"
      title={"Pedidos"}
      periodType={periodo}
      queryProps={queryProps}
      getValue={(aggregate) => {
        return aggregate ? aggregate.count : 0
      }}
    />
  )
}

export default QuantidadePedidoPanel
