import React from "react"
import { Select } from "semantic-ui-react"
import { PeriodOptions } from "../../common/helper/dateUtils"
import { Icon } from "semantic-ui-react"

export function DashboardVendasHeader({ periodo, onChange }) {
  const handleSelecionarPeriodo = (opcao) => {
    onChange(opcao?.value)
  }

  return (
    <div className="flex flex-col  items-center  justify-between mt-2 mr-2 ml-2 bg-blue-600 rounded-md md:flex-row p-2">
      <p className="text-white  font-bold text-2xl">
        <Icon name="chart line" />
        Resumo de Vendas
      </p>
      <Select
        onChange={(e, val) => handleSelecionarPeriodo(val)}
        placeholder="Selecione o periodo"
        options={PeriodOptions}
        value={periodo}
      />
    </div>
  )
}

export default DashboardVendasHeader
