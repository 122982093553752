import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { DASHBOARD_VALOR_PEDIDO_QUERY } from "./gql/queries"
import ComparableBoxInfo from "../ComparableBoxInfo"
import { getPeriods } from "../../common/helper/dateUtils"
import format from "../../common/helper/format"

const getFiltros = (period, hasPermissao, userId) => {
  let filters = {
    ficha: {
      _and: [
        { data: { _gte: period.initialDate } },
        { data: { _lte: period.finalDate } },
      ],
    },
  }

  if (!hasPermissao) {
    filters.vendedor_id = { _eq: userId }
  }

  return filters
}

const PedidoValorLiquidoPanel = ({ periodo, hasPermissao, userId }) => {
  const periodos = getPeriods(new Date(), periodo)

  const variables = {
    filtro_principal: getFiltros(periodos.current, hasPermissao, userId),
    filtro_comparacao: getFiltros(periodos.previous, hasPermissao, userId),
  }

  const queryProps = useQuery(DASHBOARD_VALOR_PEDIDO_QUERY, {
    variables,
  })

  return (
    <ComparableBoxInfo
      periods={periodos}
      icon="dollar sign"
      title={"Valor Líquido"}
      mask={(value) => format.money(value)}
      periodType={periodo}
      queryProps={queryProps}
      getValue={(aggregate) => {
        return aggregate ? aggregate.sum["valortotalliquido"] : 0
      }}
    />
  )
}

export default PedidoValorLiquidoPanel
