import { useQuery } from "@apollo/client"
import React, { useState } from "react"
import { ResponsiveBar } from "@nivo/bar"
import { formatDateToDB } from "../../common/helper/dateUtils"
import { SALES_SUMMARY_QUERY } from "../../report/analytical/sales/gql/queries"
import BoxChart from "../BoxChart"
import { Button, Icon } from "semantic-ui-react"
import { getUserId } from "../../../lib/auth"
import {
  CLASSE_RESUMO_VENDA,
  usePermissao,
} from "../../common/PermissaoContext"

const getPeriodo = (mensal, ano, mes) => {
  let dataInicial = new Date()
  let dataFinal = new Date()

  if (mensal) {
    dataInicial.setFullYear(ano, 0, 1)
    dataFinal.setFullYear(ano, 11, 31)
  } else {
    dataInicial.setFullYear(ano, mes, 1)
    dataFinal.setFullYear(ano, mes + 1, 0)
  }

  let periodo = {
    initialDate: formatDateToDB(dataInicial),
    finalDate: formatDateToDB(dataFinal),
  }

  return periodo
}

const getFiltros = (period, hasPermissao, userId) => {
  const filters = {
    _and: [
      { dataemissao: { _gte: period.initialDate } },
      { dataemissao: { _lte: period.finalDate } },
    ],
  }

  if (!hasPermissao) {
    filters.vendedor_id = { _eq: userId }
  }

  return filters
}

const PedidoVendaLiquidaChart = ({ titulo, mensal, hasPermissao }) => {
  const userId = getUserId()
  const [ano, setAno] = useState(new Date().getFullYear())
  const [mes, setMes] = useState(new Date().getMonth())

  let listName = "list_by_day"
  let groupName = "codigo"
  let filtroMes = { id: { _eq: 0 } }
  let filtroDia = {}

  if (mensal) {
    listName = "list_by_month"
    groupName = "sigla"
    filtroMes = {}
    filtroDia = { id: { _eq: 0 } }
  }

  let periodo = getPeriodo(mensal, ano, mes)
  console.log(periodo)

  const variables = {
    store: { id: { _eq: 0 } },
    payment_term: { id: { _eq: 0 } },
    saller: { id: { _eq: 0 } },
    department: { id: { _eq: 0 } },
    year: { id: { _eq: 0 } },
    month: filtroMes,
    day: filtroDia,
    hour: { id: { _eq: 99 } },
    filters: getFiltros(periodo, hasPermissao, userId),
  }

  const queryResult = useQuery(SALES_SUMMARY_QUERY, {
    variables: variables,
  })

  const { loading, data } = queryResult

  let list = []

  if (!loading && data) {
    list = data[listName]
  }

  function handleClickNext(proximoAno) {
    if (proximoAno) {
      setAno(ano + 1)
    } else {
      if (mes == 11) {
        setAno(ano + 1)
        setMes(0)
      } else {
        setMes(mes + 1)
      }
    }
  }

  function handleClickPrev(anoAnterior) {
    if (anoAnterior) {
      setAno(ano - 1)
    } else {
      if (mes == 0) {
        setAno(ano - 1)
        setMes(11)
      } else {
        setMes(mes - 1)
      }
    }
  }

  const dataGrafico2 = list.map((item, index) => {
    let somatoria = item["resumovendas_aggregate"].aggregate.sum
    return {
      key: item[groupName],
      "Total Liquido": Math.trunc(somatoria.valortotalliquido),
      "Total Bruto": Math.trunc(somatoria.valortotalbruto),
    }
  })

  const exibirMesNavegacao = !mensal

  const Navbar = (
    <Button.Group>
      <Button icon onClick={() => handleClickPrev(true)}>
        <Icon name="angle double left" />
      </Button>

      {exibirMesNavegacao && (
        <Button icon onClick={() => handleClickPrev(false)}>
          <Icon name="angle left" />
        </Button>
      )}
      <Button basic>
        {mes + 1}/{ano}
      </Button>
      {exibirMesNavegacao && (
        <Button icon onClick={() => handleClickNext(false)}>
          <Icon name="angle right" />
        </Button>
      )}
      <Button icon onClick={() => handleClickNext(true)}>
        <Icon name="angle double right" />
      </Button>
    </Button.Group>
  )

  return (
    <BoxChart title={titulo} navbar={Navbar}>
      <ResponsiveBar
        margin={{ top: 10, bottom: 30, left: 50 }}
        data={dataGrafico2}
        keys={["Total Liquido", "Total Bruto"]}
        indexBy="key"
        padding={0.5}
        colors={{ scheme: "paired" }}
        enableLabel={false}
        labelTextColor="black"
        labelSkipWidth={16}
        labelSkipHeight={16}
        colorBy="indexValue"
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 12,
                fill: "#374151",
              },
            },
          },
        }}
      />
    </BoxChart>
  )
}

export default PedidoVendaLiquidaChart
