// @flow
import React, { useState } from "react"
import BoxChart from "../BoxChart"
import BoxGroup from "../BoxGroup"
import PedidoValorLiquidoPanel from "./PedidoValorLiquidoPanel"
import QuantidadePedidoPanel from "./PedidoQuantidadePanel"
import ClienteNovoQuantidadePanel from "./ClienteNovoQuantidadePanel"
import PedidoDescontoPanel from "./PedidoDescontoPanel"
import DashboardVendasHeader from "./DashboardVendasHeader"
import { PeriodType } from "../../common/helper/dateUtils"
import PedidoVendaLiquidaChart from "./PedidoVendaLiquidaChart"
import {
  CLASSE_RESUMO_VENDA,
  usePermissao,
} from "../../common/PermissaoContext"
import { getUserId } from "../../../lib/auth"

const DashboardVendas = () => {
  const hasPermissao = usePermissao("ABRIR", CLASSE_RESUMO_VENDA)
  const userId = getUserId()

  const [periodo, setPeriodo] = useState(PeriodType.MONTH)

  return (
    <>
      <div className="flex flex-col gap-4 my-4 rounded-md ">
        <div className="justify-center items-center mt-4 mr-2 ml-2 border-2 rounded-md border-gray-200 shadow-lg ">
          <DashboardVendasHeader onChange={setPeriodo} periodo={periodo} />
          <BoxGroup className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
            <QuantidadePedidoPanel
              periodo={periodo}
              hasPermissao={hasPermissao}
              userId={userId}
            />
            <ClienteNovoQuantidadePanel periodo={periodo} />
            <PedidoValorLiquidoPanel
              periodo={periodo}
              hasPermissao={hasPermissao}
              userId={userId}
            />
            <PedidoDescontoPanel
              periodo={periodo}
              hasPermissao={hasPermissao}
              userId={userId}
            />
          </BoxGroup>
        </div>

        <BoxGroup className="grid grid-cols-1 ">
          <PedidoVendaLiquidaChart
            titulo={"Vendas por Mês"}
            mensal={true}
            hasPermissao={hasPermissao}
          />
          <PedidoVendaLiquidaChart
            titulo={"Vendas por Dia"}
            mensal={false}
            hasPermissao={hasPermissao}
          />
        </BoxGroup>
      </div>
    </>
  )
}

export default DashboardVendas
